<template>
  <div class="address-item">
    <div class="address-item__header">
      <div class="address-item__header__left">
        <div
          class="address-item__header__left__name"
        >{{ `${addressInfo.firstName} ${addressInfo.lastName}` }}</div>
        <div
          v-if="addressInfo.defaultType === ADDRESS_DEFAULT_TYPE.DEFAULT"
          class="address-item__header__left__type"
        >{{ $t('account.addressManage.defaultAddress') }}</div>
      </div>
      <div class="address-item__header__operation">
        <a-popconfirm
          :title="$t('account.addressManage.deleteConfirm')"
          :ok-text="$t('account.addressManage.delete')"
          :cancel-text="$t('account.addressManage.cancel')"
          @confirm="deleteAddress"
        >
          <span
            class="address-item__header__operation__delete-btn"
          >{{ $t('account.addressManage.delete') }}</span>
        </a-popconfirm>
        <span
          class="address-item__header__operation__edit-btn"
          @click="editAddress"
        >{{ $t('account.addressManage.edit') }}</span>
      </div>
    </div>
    <div class="address-item__phone">{{ phone }}</div>
    <div class="address-item__detail-address">{{ addressDetailInfo }}</div>
  </div>
</template>

<script>
import { deleteAddress } from '@/api'
import { ADDRESS_DEFAULT_TYPE } from '@/constants'
export default {
  name: 'AddressItem',
  components: {},
  props: {
    addressInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ADDRESS_DEFAULT_TYPE,
    }
  },

  computed: {
    phone() {
      return `${this.addressInfo.internationalCode} ${this.addressInfo.phone}`
    },
    addressDetailInfo() {
      const keyList = ['address', 'city', 'countryValue', 'stateValue', 'zipCode']
      return keyList
        .map(item => this.addressInfo[item] || '')
        .filter(item => !!item)
        .join(',')
    },
  },

  methods: {
    async deleteAddress() {
      const { message } = await deleteAddress({ customerAddrId: this.addressInfo.id })
      this.$message.success(message)
      this.$emit('getAddressList')
    },
    editAddress() {
      this.$emit('editAddress', this.addressInfo)
    },
  },
}
</script>
<style lang="less" scoped>
.address-item {
  padding: 16px;
  margin-bottom: 16px;
  color: var(--color-text);
  background: #f9fafb;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;
      &__name {
        font-size: 16px;
      }
      &__type {
        padding: 4px 8px;
        margin-left: 8px;
        color: #00b876;
        background: #e5f8f1;
      }
    }
    &__operation {
      &__delete-btn {
        margin-right: 16px;
        color: #f65160;
        cursor: pointer;
      }
      &__edit-btn {
        color: #00b876;
        cursor: pointer;
      }
    }
  }
  &__phone {
    margin: 8px 0;
  }
  &__detail-address {
    color: var(--color-text);
  }
}
</style>
