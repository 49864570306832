<template>
  <div class="address-edit">
    <div class="address-edit__title">
      {{
        addressDetail.id ? $t('account.addressManage.edit') : $t('account.addressManage.addAddress')
      }}
    </div>
    <address-form
      ref="addressForm"
      :address-detail="addressDetail"
      class="address-edit__form"
    ></address-form>
    <a-checkbox
      v-model="defaultType"
      :default-checked="defaultType === ADDRESS_DEFAULT_TYPE.DEFAULT"
      class="address-edit__default-set"
    >{{ $t('account.addressManage.setDefault') }}</a-checkbox>
    <div class="address-edit__operation">
      <span
        class="address-edit__operation__cancel-btn"
        @click="cancel"
      >{{ $t('account.addressManage.cancel') }}</span>
      <op-button @click="summit">{{ $t('account.addressManage.add') }}</op-button>
    </div>
  </div>
</template>

<script>
import { addAddress, updateAddress } from '@/api'
import { ADDRESS_DEFAULT_TYPE, ADDRESS_TYPE } from '@/constants'

export default {
  name: 'AddressEdit',
  props: {
    addressDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      defaultType: false,
      ADDRESS_DEFAULT_TYPE: ADDRESS_DEFAULT_TYPE,
    }
  },

  computed: {},

  created() {
    this.defaultType = this.addressDetail.defaultType === ADDRESS_DEFAULT_TYPE.DEFAULT
  },

  methods: {
    cancel() {
      this.changeComponentName()
    },
    async summit() {
      const { valid } = await (this.$refs.addressForm && this.$refs.addressForm.submit())
      if (!valid) {
        return
      }
      this.addressDetail.id
        ? this.updateAddress(this.addressDetail)
        : this.addAddress(this.addressDetail)
    },

    async addAddress(address) {
      const { message } = await addAddress({
        ...address,
        addressType: ADDRESS_TYPE.POST_ADDRESS,
        defaultType: this.defaultType
          ? ADDRESS_DEFAULT_TYPE.DEFAULT
          : ADDRESS_DEFAULT_TYPE.NOT_DEFAULT,
      })
      this.$message.success(message)
      this.changeComponentName()
    },
    async updateAddress(address) {
      const { message } = await updateAddress({
        ...address,
        id: this.addressDetail.id,
        addressType: ADDRESS_TYPE.POST_ADDRESS,
        defaultType: this.defaultType
          ? ADDRESS_DEFAULT_TYPE.DEFAULT
          : ADDRESS_DEFAULT_TYPE.NOT_DEFAULT,
      })
      this.$message.success(message)
      this.changeComponentName()
    },
    changeComponentName() {
      this.$emit('changeComponentName', { componentName: 'address-list' })
    },
  },
}
</script>
<style lang="less" scoped>
.address-edit {
  &__title {
    padding: 24px;
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    color: var(--color-title);
    border-bottom: 1px solid #f4f6f8;
  }
  &__form {
    padding: 24px 24px 0;
  }
  &__default-set {
    padding: 0 24px 24px;
  }
  &__operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px 24px;
    &__cancel-btn {
      color: #8991a4;
      cursor: pointer;
    }
  }
}
</style>
