<template>
  <div class="address">
    <address-list
      v-if="currentComponent === 'address-list'"
      @changeComponentName="changeComponentName"
    ></address-list>
    <address-edit
      v-if="currentComponent === 'address-edit'"
      :address-detail="addressDetail"
      @changeComponentName="changeComponentName"
    ></address-edit>
  </div>
</template>

<script>
import AddressList from './components/address-list.vue'
import AddressEdit from './components/address-edit.vue'
export default {
  name: 'AddressManage',
  components: {
    AddressList,
    AddressEdit,
  },
  props: {},
  data() {
    return {
      currentComponent: 'address-list',
      addressDetail: {},
    }
  },

  computed: {},

  methods: {
    changeComponentName(componentInfo) {
      this.currentComponent = componentInfo.componentName
      this.addressDetail = componentInfo.data || {}
    },
  },
}
</script>
<style lang="less" scoped></style>
