<template>
  <div class="order-list">
    <div class="order-list__tabs">
      <div class="order-list__tabs__order-status-tab">{{ $t('account.orderList.all') }}</div>
    </div>
    <a-row
      class="order-list-row order-list-row__header order-list-row--pc"
      type="flex"
      align="middle"
    >
      <a-col flex="1">{{ $t('account.orderList.orderNo') }}</a-col>
      <a-col flex="1">{{ $t('account.orderList.orderTotal') }}</a-col>
      <a-col flex="1">{{ $t('account.orderList.orderDate') }}</a-col>
      <a-col flex="1">{{ $t('account.orderList.orderStatus') }}</a-col>
      <a-col flex="1">{{ $t('account.orderList.operate') }}</a-col>
    </a-row>
    <div v-show="!loading">
      <a-row
        v-for="item in orderList"
        :key="item.orderNo + 'pc'"
        class="order-list-row order-list-row--pc"
        type="flex"
        align="middle"
      >
        <a-col flex="1">
          <op-link @click="goOrderDetail(item.orderNo, item.orderToken)">{{ item.orderNo }}</op-link>
        </a-col>
        <a-col flex="1">{{ item.total | formatMoney }}</a-col>
        <a-col flex="1">{{ item.orderTime | formatDateTime }}</a-col>
        <a-col flex="1">
          <div
            v-if="item.orderStatus === ORDER_STATUS.UNPAID"
            class="order-list-row__order-status order-list-row__order-status--unpaid"
          >
            <div class="order-list-row__order-status__icon"></div>
            <div class="order-list-row__order-status__text">{{ item.orderStatusName }}</div>
          </div>
          <div
            v-else-if="item.orderStatus === ORDER_STATUS.DELIVERED"
            class="order-list-row__order-status order-list-row__order-status--processing"
          >
            <div class="order-list-row__order-status__icon"></div>
            <div class="order-list-row__order-status__text">{{ item.orderStatusName }}</div>
          </div>
          <div
            v-else
            class="order-list-row__order-status"
          >
            <div class="order-list-row__order-status__icon"></div>
            <div class="order-status__text">{{ item.orderStatusName }}</div>
          </div>
        </a-col>
        <a-col flex="1">
          <op-link
            @click="goOrderDetail(item.orderNo, item.orderToken)"
          >{{ $t('account.orderList.viewDetail') }}</op-link>
        </a-col>
      </a-row>
      <a-row
        v-for="item in orderList"
        :key="item.orderNo + 'mobile'"
        class="order-list-row--mobile order-list-row"
        align="middle"
        :style="{ background: '#f9fafb', marginBottom: '16px' }"
      >
        <div class="order-list-row__item-row">
          <op-link @click="goOrderDetail(item.orderNo, item.orderToken)">{{ item.orderNo }}</op-link>
          <div
            v-if="item.orderStatus === ORDER_STATUS.UNPAID"
            class="order-list-row__order-status order-list-row__order-status--unpaid"
          >
            <div class="order-list-row__order-status__icon"></div>
            <div class="order-list-row__order-status__text">{{ item.orderStatusName }}</div>
          </div>
          <div
            v-else-if="item.orderStatus === ORDER_STATUS.DELIVERED"
            class="order-list-row__order-status order-list-row__order-status--processing"
          >
            <div class="order-list-row__order-status__icon"></div>
            <div class="order-list-row__order-status__text">{{ item.orderStatusName }}</div>
          </div>
          <div
            v-else
            class="order-list-row__order-status"
          >
            <div class="order-list-row__order-status__icon"></div>
            <div class="order-list-row__order-status__text">{{ item.orderStatusName }}</div>
          </div>
        </div>
        <div class="order-list-row__item-row">{{ item.orderTime | formatDateTime }}</div>
        <div class="order-list-row__item-row">
          <div>{{ item.total | formatMoney }}</div>
          <op-link
            @click="goOrderDetail(item.orderNo, item.orderToken)"
          >{{ $t('account.orderList.viewDetail') }}</op-link>
        </div>
      </a-row>
    </div>
    <a-empty v-if="!loading && total === 0" />
    <op-spin v-if="loading"></op-spin>
    <div class="order-list__pagination">
      <a-pagination
        v-model="currentPage"
        size="small"
        :total="total"
        :default-page-size="pageSize"
        :show-total="
          total => `${$t('common.pagination.total')} ${total} ${$t('common.pagination.items')}`
        "
        @change="getOrderList"
      />
    </div>
  </div>
</template>

<script>
import { getOrderList } from '@/api'
import { ORDER_STATUS } from '@/constants'
import { mapState } from 'vuex'
export default {
  components: {},
  props: {},
  data() {
    return {
      orderList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      ORDER_STATUS: ORDER_STATUS,
    }
  },

  computed: {
    ...mapState(['isAlreadyLogin']),
  },

  watch: {
    isAlreadyLogin(val) {
      if (val) {
        this.getOrderList()
      }
    },
  },

  created() {
    if (this.isAlreadyLogin) {
      this.getOrderList()
    } else {
      this.$store.commit('openLoginDialog', {
        currentForm: 'loginForm',
      })
    }
  },

  methods: {
    async getOrderList() {
      this.loading = true
      const params = {
        size: this.pageSize,
        start: this.pageSize * (this.currentPage - 1),
      }

      try {
        const { data, totalRecords } = await getOrderList(params)

        this.total = totalRecords || 0
        this.orderList = data || []
      } finally {
        this.loading = false
      }
    },
    goOrderDetail(orderNo, orderToken) {
      this.$router.push({
        path: `/order/${orderNo}`,
        query: { orderToken },
      })
    },
    handleTableChange(pagination) {
      this.pagination = pagination
      this.getOrderList()
    },
  },
}
</script>
<style lang="less" scoped>
.order-list {
  position: relative;
  padding: 24px;
  word-break: break-all;
  &-row__header {
    background: #f9fafb;
  }
  &-row {
    /deep/ .ant-col {
      padding: 4px;
    }

    &__item-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
    }

    &__order-status {
      display: inline-flex;
      align-items: center;
      padding: 4px;
      color: #2a334a;
      background: #fafafb;
      border-radius: 2px;
      &__icon {
        width: 6px;
        height: 6px;
        margin-right: 2px;
        background: #8991a4;
        border-radius: 50%;
      }
    }
    &__order-status--unpaid {
      color: #2a334a;
      background: #feeeef;
      .order-status__icon {
        background: #f65160;
      }
    }
    &__order-status--processing {
      color: #2a334a;
      background: #e5f8f1;
      .order-status__icon {
        background: #00b876;
      }
    }

    padding: 16px;
    border-bottom: 1px solid #f4f6f8;
  }
  &-row--mobile {
    display: none;
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
    margin: 24px 0 0;
  }

  &__tabs {
    display: flex;
    padding-bottom: 24px;
    &__order-status-tab {
      padding-bottom: 4px;
      line-height: 22px;
      color: var(--color-primary-button-background);
      border-bottom: 1px solid var(--color-primary-button-background);
    }
  }
}
@media (max-width: 768px) {
  .order-list {
    .order-list-row--pc {
      display: none;
    }
    .order-list-row--mobile {
      display: block;
    }
  }
}
</style>
