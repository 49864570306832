<template>
  <div class="address-list">
    <div class="address-list__header">
      <span class="address-list__header__title">{{ $t('account.addressManage.allAddress') }}</span>
      <op-button @click="addAddress">{{ $t('account.addressManage.addAddress') }}</op-button>
    </div>
    <div
      v-show="!loading"
      class="address-list__body"
    >
      <address-item
        v-for="item in addressList"
        :key="item.id"
        :address-info="item"
        @editAddress="editAddress"
        @getAddressList="getAddressList"
      ></address-item>
    </div>
    <a-empty v-if="!loading && addressList && addressList.length === 0" />
    <op-spin v-if="loading"></op-spin>
  </div>
</template>

<script>
import AddressItem from './address-item.vue'
import { getAddressList } from '@/api'
import { mapState } from 'vuex'
export default {
  name: 'AddressList',
  components: {
    AddressItem,
  },
  props: {},
  data() {
    return {
      addressList: [],
      loading: false,
    }
  },

  computed: {
    ...mapState(['userInfo', 'isAlreadyLogin']),
  },

  watch: {
    isAlreadyLogin(val) {
      if (val) {
        this.getAddressList()
      }
    },
  },

  created() {
    if (this.isAlreadyLogin) {
      this.getAddressList()
    } else {
      this.$store.commit('openLoginDialog', {
        currentForm: 'loginForm',
      })
    }
  },

  methods: {
    async getAddressList() {
      // todo 下一个版本需求优化
      try {
        this.loading = true
        const { data } = await getAddressList({ customerId: this.$store.state.userInfo.customerId })
        this.addressList = data || []
      } finally {
        this.loading = false
      }
    },
    addAddress() {
      this.$emit('changeComponentName', { componentName: 'address-edit' })
    },
    editAddress(addressData) {
      this.$emit('changeComponentName', { componentName: 'address-edit', data: addressData })
    },
  },
}
</script>
<style lang="less" scoped>
.address-list {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 24px;
    border-bottom: 1px solid #f4f6f8;
    &__title {
      font-size: 22px;
      font-weight: 500;
      color: var(--color-title);
    }
  }
  &__body {
    padding: 16px;
  }
}
</style>
