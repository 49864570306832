<template>
  <div class="account">
    <div class="account__left">
      <div class="account__left__header">
        <span>{{ $t('account.tabs.myAccount') }}</span>
        <op-link
          class="account__left__header__logout"
          @click="logout"
        >{{ $t('account.tabs.logout') }}</op-link>
      </div>
      <div class="account__left__mine">
        <img
          :src="avatarUrl"
          class="account__left__mine__mine-avatar"
        />
        <div class="account__left__mine__info">
          <div class="account__left__mine__info__name">{{ userName }}</div>
          <div class="account__left__mine__info__email">{{ userInfo.mail || '' }}</div>
        </div>
      </div>
      <div class="account__left__tabs">
        <div
          v-for="item in tabList"
          :key="item.id"
          :class="[
            item.componentName === currentComponent ? 'account__left__tabs__item--active' : '',
          ]"
          class="account__left__tabs__item"
          @click="changeComponentName(item.componentName)"
        >{{ $t(item.name) }}</div>
      </div>
      <op-link
        class="account__left__logout"
        @click="logout"
      >{{ $t('account.tabs.logout') }}</op-link>
    </div>
    <div class="account__right">
      <keep-alive>
        <component
          :is="currentComponent"
          @changeComponentName="changeComponentName"
        ></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import AddressManage from './address-manage/index.vue'
import OrderList from './order/order-list/index.vue'
export default {
  components: {
    AddressManage,
    OrderList,
  },
  props: {},
  data() {
    return {
      avatarUrl:
        'https://saas-fe-prod.pandova.cloud/static/pandova-mall/images/account/mine-logo.png',
      tabList: [
        {
          id: 'order',
          name: 'account.tabs.myOrder',
          componentName: 'order-list',
        },
        {
          id: 'address',
          name: 'account.tabs.addressManage',
          componentName: 'address-manage',
        },
      ],
      currentComponent: 'order-list',
      currentTab: '',
    }
  },

  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    userName() {
      return (this.userInfo.firstName || '') + ' ' + (this.userInfo.lastName || '')
    },
  },

  created() {
    const isAddressManage = this.$route.query.components === 'address-manage'
    this.currentComponent = isAddressManage ? 'address-manage' : 'order-list'
  },

  methods: {
    async logout() {
      await this.$store.dispatch('logout')
      this.$router.push('/')
    },
    changeComponentName(componentName) {
      this.currentComponent = componentName
    },
  },
}
</script>
<style lang="less" scoped>
.account {
  display: flex;
  width: 1440px;
  max-width: calc(100% - 80px);
  padding: 24px 0;
  margin: 0 auto;
  &__left {
    width: 300px;
    padding-bottom: 24px;
    margin-right: 24px;
    font-size: 14px;
    font-weight: 500;
    background: #fff;
    &__header {
      padding: 24px;
      font-size: 22px;
      font-weight: 500;
      line-height: 22px;
      color: var(--color-title);
      border-bottom: 1px solid #f4f6f8;
      &__logout {
        display: none;
      }
    }
    &__mine {
      display: flex;
      align-items: center;
      padding: 24px 24px 0;
      &__mine-avatar {
        display: block;
        width: 64px;
        height: 64px;
        margin-right: 16px;
        object-fit: cover;
      }
    }
    &__tabs {
      padding: 24px;
      &__item {
        height: 40px;
        padding-left: 16px;
        line-height: 40px;
        cursor: pointer;
      }
      &__item:not(:last-child) {
        margin-bottom: 8px;
      }
      &__item--active {
        color: #fff;
        background: var(--color-primary-button-background);
        border-radius: 4px;
      }
    }
    &__logout {
      padding-left: 40px;
    }
  }
  &__right {
    flex: 1;
    background: #fff;
  }
}
@media (max-width: 1024px) {
  .account {
    display: block;
    max-width: calc(100% - 80px);
    margin: 0 auto;
    &__left {
      width: 100%;
      text-align: center;
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__logout {
          display: block;
        }
      }
      &__mine {
        display: block;
        padding: 24px 24px 0;
        text-align: center;
        &__mine-avatar {
          display: block;
          width: 64px;
          height: 64px;
          margin: 0 auto;
          object-fit: cover;
        }
      }

      &__tabs {
        display: flex;
        justify-content: center;
        &__item {
          padding: 0 16px;
          margin-right: 8px;
        }
        &__item--active {
          color: var(--color-primary-button-background);
          background: unset !important;
          border-bottom: 1px solid var(--color-primary-button-background);
          border-radius: 0;
        }
      }
      &__logout {
        display: none;
      }
    }
  }
}

@media (max-width: 576px) {
  .account {
    max-width: calc(100% - 32px);
    margin: 0 auto;
  }
}
</style>
